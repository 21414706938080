import React, { useEffect } from 'react'
import IframeComm from "react-iframe-comm";
import { createNewWallet, poaSign, poaNormalSign, ethContractSign } from './Blockchain'
import { isValidAddress } from './RuleUtils'
import db from './model/db'

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';


var height = document.documentElement.clientHeight

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const [openDappDialog, setOpenDappDialog] = React.useState(false);
  const [dappUrl, setDappUrl] = React.useState("");

  const requestWebAuth = () => {
    var createCredentialDefaultArgs = {
      publicKey: {
          // Relying Party (a.k.a. - Service):
          rp: {
              name: "Acme"
          },
  
          // User:
          user: {
              id: new Uint8Array(16),
              name: "john.p.smith@example.com",
              displayName: "John P. Smith"
          },
  
          pubKeyCredParams: [{
              type: "public-key",
              alg: -7
          }],
  
          authenticatorSelection: {
            authenticatorAttachment: "platform",
          },

          attestation: "direct",
  
          timeout: 60000,
  
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x8C, 0x0A, 0x26, 0xFF, 0x22, 0x91, 0xC1, 0xE9, 0xB9, 0x4E, 0x2E, 0x17, 0x1A, 0x98, 0x6A, 0x73,
              0x71, 0x9D, 0x43, 0x48, 0xD5, 0xA7, 0x6A, 0x15, 0x7E, 0x38, 0x94, 0x52, 0x77, 0x97, 0x0F, 0xEF
          ]).buffer
        }
    };

    var getCredentialDefaultArgs = {
      publicKey: {
          timeout: 60000,
          // allowCredentials: [newCredential] // see below
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x79, 0x50, 0x68, 0x71, 0xDA, 0xEE, 0xEE, 0xB9, 0x94, 0xC3, 0xC2, 0x15, 0x67, 0x65, 0x26, 0x22,
              0xE3, 0xF3, 0xAB, 0x3B, 0x78, 0x2E, 0xD5, 0x6F, 0x81, 0x26, 0xE2, 0xA6, 0x01, 0x7D, 0x74, 0x50
          ]).buffer
      },
    };

    navigator.credentials.create(createCredentialDefaultArgs)
    .then((cred) => {
        console.log("NEW CREDENTIAL", cred);

        // normally the credential IDs available for an account would come from a server
        // but we can just copy them from above...
        var idList = [{
            id: cred.rawId,
            transports: ["platform"],
            type: "public-key"
        }];
        getCredentialDefaultArgs.publicKey.allowCredentials = idList;
        return navigator.credentials.get(getCredentialDefaultArgs);
    })
    .then((assertion) => {
        console.log("ASSERTION", assertion);
        alert("ASSERTION "+assertion)

    })
    .catch((err) => {
        console.log("ERROR", err);
        alert("err "+err)
    });
  }

  return (
    <React.Fragment>
      <Box style={{
        width: "100%",
        height: height,
        // backgroundColor:"#000000",
      }}>
        <IframeComm
              attributes={{
                id: "iframe",
                src: process.env.REACT_APP_EX_SUB_URL,
                position: 'fixed',
                width:'100%',
                top: 'auto',
                height: height,
              }}
              postMessageData={"test99 hello iframe"}
              handleReady={()=>{
                // console.log("test99 onReady");
              }}
              handleReceiveMessage={(event)=>{
                if (event.data.method) {
                  var iframe = document.getElementById('iframe').contentWindow

                  // console.log("test99 onReceiveMessage "+JSON.stringify(event.data))
                  const request = event.data.data

                  switch (event.data.method) {
                    case "getWallet":
                      const poaAddress = localStorage.getItem('poaAddress_aqn')
                      if (poaAddress && isValidAddress(poaAddress)) {
                        iframe.postMessage({method:"returnWallet", data:poaAddress}, '*');
                      } else {
                        iframe.postMessage({method:"returnWallet", data:"EMPTY"}, '*');
                      }
                      break
                    case "getLocation":
                        const location = window.location.href;
                        console.log('getLocation: '+location);
                        window.localStorage.setItem('location', location);
                        iframe.postMessage({method:"returnLocation", data:location}, '*');
                        break
                    case "getTgId":
                      iframe.postMessage({method:"returnTgId", data:"0"}, '*');
                      break
                    case "getAuthAddress":

                      db.table_wallet.where('chainType').anyOf(1).first((wallet)=>{            
                        if(wallet) {
                          iframe.postMessage({method:"returnAuthAddress", data:wallet.address}, '*');
                        } else {
                          iframe.postMessage({method:"returnAuthAddress", data:""}, '*');
                        }
                      })
                      
                      break
                    case "generatePky":
                      createNewWallet().then(ethAddress=>{
                        console.log('createNewWallet '+ethAddress)

                        iframe.postMessage({method:"returnAuthAddress", data:ethAddress}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "setPoaAddress":
                      window.localStorage.setItem('poaAddress_aqn', request);
                      break
                    case "signWalletData":
                      poaSign(request).then(signature=>{
                        if(signature === "") {
                          return
                        }
                        console.log('ethSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnWalletSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signEthWalletContractData":
                      ethContractSign(request.signData, request.toAddress).then(signature=>{
                        if(signature === "") {
                          return
                        }
                        // console.log('signEthWalletContractData '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnEthWalletContractSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signNormalData":
                      poaNormalSign(request.signData, request.toAddress).then(signature=>{
                        if(signature === "") {
                          return
                        }
                        // console.log('ethNormalSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnNormalSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "launchDapp":
                      setDappUrl(request)
                      setOpenDappDialog(true)
                      break
                    case "closeDapp":
                      setOpenDappDialog(false)
                      break
                    case "launchChat":
                      
                      break
                    case "appAuthorize":
                      requestWebAuth()
                      break
                    default:
                  }
                }
              }}
        />
        <Dialog fullScreen   open={openDappDialog} onClose={()=>{

        }} TransitionComponent={Transition}>
          <IframeComm
              attributes={{
                id: "iframeDapp",
                src: dappUrl,
                width: "100%",
                height: height,
              }}
              postMessageData={"test99 hello iframe"}
              handleReady={()=>{
                console.log("test99 onReady");
              }}
              handleReceiveMessage={(event)=>{
                if (event.data.method) {
                  var iframeDapp = document.getElementById('iframeDapp').contentWindow

                  // console.log(JSON.stringify(event.data))
                  switch (event.data.method) {
                    case "getWallet":
                      const poaAddress = localStorage.getItem('poaAddress_aqn')
                      if (poaAddress && isValidAddress(poaAddress)) {
                        iframeDapp.postMessage({method:"returnWallet", data:poaAddress}, '*');
                      } else {
                        iframeDapp.postMessage({method:"returnWallet", data:"EMPTY"}, '*');
                      }
                      break
                    case "getTgId":
                      iframeDapp.postMessage({method:"returnTgId", data:"0"}, '*');
                      break
                    case "signData":
                      poaSign(event.data.data).then(signature=>{
                        if(signature === "") {
                          return
                        }
                        console.log('ethSign '+JSON.stringify(signature))

                        iframeDapp.postMessage({method:"returnSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    default: 

                  }
                }
              }}
            />
        </Dialog>
        </Box>
    </React.Fragment>


  );
}

export default Home;
